<template>
  <div>
    <base-header class="pb--6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
        </div>
      </div>
      <!-- Card stats -->
      <div class="row" v-if=isAdmin>
        <div class="col-xl-4 col-md-4">
          <stats-card title="Total clients"
                      type="gradient-red"
                      v-bind:sub-title=totalClients
                      icon="ni ni-active-40">

            <template slot="footer">
              <!-- <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 100%</span> -->
              <!-- <span class="text-nowrap">Since last month</span> -->
            </template>
          </stats-card>

        </div>
        <div class="col-xl-4 col-md-4">
          <stats-card title="Total projects"
                      type="gradient-orange"
                      v-bind:sub-title=totalProjects
                      icon="ni ni-chart-pie-35">

            <template slot="footer">
              <!-- <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 100%</span>
              <span class="text-nowrap">Since last month</span> -->
            </template>
          </stats-card>
        </div>
<div class="col-xl-4 col-md-4">
          <stats-card title="Total appointments"
                      type="gradient-red"
                      v-bind:sub-title=totalAppointments
                      icon="ni ni-active-40">

            <template slot="footer">
              <!-- <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 100%</span>
              <span class="text-nowrap">Since last month</span> -->
            </template>
          </stats-card>

        </div>
        </div>
      <!-- Calendar -->
    </base-header>
    <div class="row">
      <calendar></calendar>
    </div>
    

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';

  // Lists
  import ActivityFeed from './ActivityFeed';
  import TaskList from './TaskList';
  import UserList from './UserList';
  import ProgressTrackList from './ProgressTrackList';

  // Tables
  import LightTable from './LightTable';
  import SocialTrafficTable from './SocialTrafficTable';
  import PageVisitsTable from './PageVisitsTable';

  // Calendar
  import Calendar from "@/views/Calendar/Calendar.vue";
  import Vuex from "vuex";

  export default {
    components: {
      ActivityFeed,
      LineChart,
      BarChart,
      BaseProgress,
      RouteBreadCrumb,
      StatsCard,
      TaskList,
      PageVisitsTable,
      SocialTrafficTable,
      LightTable,
      UserList,
      ProgressTrackList,
      Calendar
    },
    data() {
      return {
        isAdmin: false,
        totalClients: "0",
        totalProjects: "0",
        totalAppointments: "0",
        me: false
        }
    },
    async created() {
      await this.$store.dispatch("profile/me");
      this.me = await this.$store.getters["profile/me"];

      if(this.me.roles[0].name == 'admin')
        this.isAdmin = true;

      if(this.isAdmin == true)
        this.countTotals();
    },
    methods: {
      async countTotals() {
        let params = {
          page: {
            number: 1,
            size: 100,
          },
        };

        await this.$store.dispatch("users/list", params);
        this.totalClients = this.$store.getters["users/listTotal"] - 1;
        if(this.totalClients == 0)
        {
          this.totalClients = "0";
        }

        await this.$store.dispatch("projects/list", params);
        this.totalProjects = this.$store.getters['projects/listTotal'];
        if(this.totalProjects == 0)
        {
          this.totalProjects = "0";
        }

        await this.$store.dispatch("appointments/list", params);
        this.totalAppointments = this.$store.getters["appointments/list"].length;
        if(this.totalAppointments == 0)
        {
          this.totalAppointments = "0";
        }
      }
    }
  };
</script>
<style></style>
