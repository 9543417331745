<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">View Folder: {{ this.folder.name }}</h3>
            </div>
            <div class="col-4 text-right">
             <base-button
                @click="download()"
                type="button"
                class="btn btn-sm btn-primary"
                >Download ZIP</base-button
              >
              </div>
          </div>
          <div class="card-body row">
              <div class="col-md-3" v-for="media in this.folder.media" :key="media.id">
                <div class="card" v-if="media.isImage">
                    <!-- Card image -->
                    <img @click="showImageModal(media)" class="card-img-top" :src="media.name" alt="Image">
                    <!-- Card body -->
                    <div class="card-body">
                    <!-- <a type="text" class="btn btn-link px-0">View</a> -->
                    </div>
                </div>
                <div class="card" v-else>
                <video
                  id="my-video"
                  class="video-js w-auto"
                  controls
                  preload="auto"
                  data-setup="{}"
                >
                  <source :src="media.name" type="video/mp4" />
                  <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank"
                      >supports HTML5 video</a
                    >
                  </p>
                </video>
                <div class="card-body">
                    <!-- <a type="text" class="btn btn-link px-0">View</a> -->
                    </div>
                </div>
              </div>
          </div>

            <modal modalClasses="modal-dialog-img" :show.sync="modals.img"
                   body-classes="p-0" body-style="display: block;">

                  <img :src="selectedImage" style="height: 100%; width: 100%; display: block;"/>

            </modal>
          <modal :show.sync="modals.form"
                   size="sm"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template slot="header">
                  <div class="text-muted text-center mb-3">
                    <small>Upload media to this folder</small>
                  </div>
                </template>
                <template>
                  <form enctype="multipart/form-data" role="form" @submit.prevent="handleSubmit" class="text-center">
                    <div class="form-group">
                <label class="form-control-label"> </label>
                <div v-if="image" class="profile-image card-img" style="margin-left: 48% !important; transform: translateX(-50%) !important;">
                  <img :src="`${image}`" class="profile-image argon-image" />
                </div>
                <div v-else class="profile-image" style="margin-left: 48% !important; transform: translateX(-50%) !important;">
                  <img src="/img/placeholder.jpg" class="argon-image" />
                </div>
                <div class="image-upload">
                  <base-button
                    v-if="image"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeImage"
                  >
                    <span>
                      <i class="fa fa-times" />
                      Remove
                    </span>
                  </base-button>
                  <base-button type="button" class="btn btn-sm btn-primary">
                    <label v-if="!image" for="imageInput" class="mb-0"
                      >Select media</label
                    >
                    <label v-else for="imageInput" class="mb-0">Change</label>
                    <input
                      id="imageInput"
                      ref="imageInput"
                      accept="image/*,video/*"
                      multiple="multiple"
                      type="file"
                      style="display: none"
                      @input="onSelectFile"
                    />
                  </base-button>
                </div>
              </div>
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Upload Media
                </base-button>
                    </form>
                </template>
              </card>

            </modal>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Modal } from '@/components';
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import JSZip from "jszip";
import saveAs from "save-as";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
      Modal
  },

  data() {
    return {
        isAdmin: false,
        image: "",
        file: null,
        selectedImage: "",
        modals: {
            form: false,
            img: false
        },
        media: {
          type: 'media',
          folder_id: null,
          name: null,
          isImage: false
        },
        folder: {
            name: null,
        },
        formModal: {
          email: ''
        }
    };
  },
  created() {
    this.isAdmin = this.$store.state.isAdmin;
    this.get();
  },

    methods: {
        showImageModal(media)
        {
          this.modals.img = true
          this.selectedImage = media.name;
        },
        async handleSubmit()
        {
            try {
                if (this.file) {
                  for (const f of this.file) {
                    let url = await this.$store.dispatch("folders/mediaUpload", {
                        folder: this.folder,
                        image: f,
                    });

                    this.media.name = "https://" + url;
                    this.media.folder_id = this.$route.params.id;

                    await this.$store.dispatch("folders/mediaInsert", this.media);
                  }
                  
                }

                this.modals.form = false;
                this.image = "";
                this.file = null;
                this.get();
                
                this.$notify({
                    type: "success",
                    message: "Media uploaded successfully.",
                });
            } catch(error) {
                console.log(error);
                console.log(error.response);
                this.$notify({
                    type: "danger",
                    message: "Oops, something went wrong!",
                });
            }
        },

        async get() {
            try {
                const share_link = this.$route.params.share_link;
                await this.$store.dispatch("folders/getPublicOnly", share_link);
                this.folder = this.$store.getters["folders/folder"];

                for (const mediaObj of this.folder.media)
                {
                  let fileExt = mediaObj.name.split('.').pop();
                  if(fileExt == "mp4")
                    mediaObj.isImage = false;
                  else
                    mediaObj.isImage = true;
                }

                console.log(this.folder);
            } catch (error) {
                console.log(error);
                console.log(error.response);
                this.$notify({
                    type: "danger",
                    message: `Oops, something went wrong!`,
                });

                this.setApiValidation(error.response.data.errors);
            }
        },

        async onSelectFile(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files);
        },

        removeImage() {
            this.image = null;
        },

        createImage(files) {
          this.file = files;
          
          let reader = new FileReader();
          reader.onload = (e) => {
              this.image = e.target.result;
          };

          reader.readAsDataURL(files[0]);
        },
        
        upload()
        {
            this.$router.push({
                name: "Add Folder",
                params: {
                    project_id: this.$route.params.id
                }
            });
        },

        async deleteMedia(id)
        {
          const confirmation = await swal.fire({
            title: `Delete this media?`,
            type: "question",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });

          try {
            if (confirmation.value === true) {
              await this.$store.dispatch("folders/mediaDestroy", id);
              this.$notify({
                type: "success",
                message: "Media deleted successfully.",
              });
            }

            this.get();
          } catch (error) {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
        },

        goBack() {
            this.$router.push({
                name: "List Projects"
            });
        },
        async download()
        {
          const ZIP = new JSZip();

          let folder_path = "/img/folders/" + this.folder.id;
          let zipFolder = ZIP.folder("media");

          this.folder.media.map((mediaObj) => {
            fetch(mediaObj.name).then((res) => {
              let response = res.clone();
              const fileName = mediaObj.name.substring(mediaObj.name.lastIndexOf('/') + 1);

              response.blob().then((blobObj) => {
                zipFolder.file(fileName, blobObj, {base64: true});
              });
            });
          });

          setTimeout(() => {
            ZIP.generateAsync({
              type: "blob"
            }).then(function(content) {
              saveAs(content, "archive_firstlook.zip");
            });
          }, "500");
        }
  },
};
</script>
