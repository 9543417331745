<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">View Project {{ project.name }}</h3>
            </div>
            <div class="col-4 text-right">
            </div>
          </div>
          
          <div class="card-body row">
              <div class="col-md-3" v-for="folder in this.project.folders" :key="folder.id">
                <div class="card">
                    <!-- Card image -->
                    <img @click="viewFolder(folder.id)" class="card-img-top" :src=folder.folder_image alt="">
                    <!-- Card body -->
                    <div class="card-body">
                    <h5 class="h2 card-title mb-0">{{ folder.name }}</h5>
                    <!-- <base-button type="button" @click="viewFolder(folder.id)" class="btn btn-link px-0">View More</base-button> -->
                    </div>
                </div>
              </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
  },

  data() {
    return {
      isAdmin: false,
      project: {
          name: null,
          is_public: null,
          share_link: null,
      }
    };
  },
  created() {
    this.get();
    this.isAdmin = this.$store.state.isAdmin;
  },

    methods: {
        async get() {
            try {
                const id = this.$route.params.share_link;
                await this.$store.dispatch("projects/getPublic", id);
                this.project = this.$store.getters["projects/project"];
                this.mapPlaceholders();
            } catch (error) {
                this.$notify({
                    type: "danger",
                    message: `Oops, something went wrong!`,
                });

                this.setApiValidation(error.response.data.errors);
            }
        },

        async makePublic()
        {
          try {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for ( let i = 0; i < 36; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            const id = this.$route.params.id;
            await this.$store.dispatch("projects/get", id);
            this.project = this.$store.getters["projects/project"];
            this.project.share_link = result;
            
            if(this.project.share_link == null)
              delete this.project.share_link;

            this.project.is_public = !this.project.is_public;

            const type = this.project.is_public == 0 ? "private" : "public";

            await this.$store.dispatch("projects/update", this.project);
            this.mapPlaceholders();
           
            this.$notify({
              type: "success",
              message: "Project made "+ type +" successfully.",
            });
          } catch (error) {
            console.log(error);
            console.log(error.response);
          }
        },
        
        addFolder()
        {
            this.$router.push({ name: "Add Folder", params: { project_id:  this.$route.params.id} });
        },

        async copyPublicLink()
        {
          let title = "Public link copied to your clipboard, or you can copy it here: http://159.223.159.100/public/" + this.project.share_link;
          await swal.fire({
            title: `Link copied successfully!`,
            text: title,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          });
        },

        mapPlaceholders()
        {
          this.project.is_public = (this.project.is_public == 0) ? "No" : "Yes";

          this.project.folders.map((folder) => {
            if(folder.folder_image == 'placeholder')
              folder.folder_image = '/img/placeholder-folder.png';
            else
              folder.folder_image = "https://" + folder.folder_image;
          });

        },
    
    viewFolder(id)
    {
      this.$router.push({
        name: 'View Public Project Folder',
        params: {
            share_link: this.$route.params.share_link,
            id: id
        }
      })
    },

    goBack() {
      this.$router.push({ name: "List Projects" });
    },
  },
};
</script>